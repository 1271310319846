"use client";

import { Sonner as SonnerADS } from "@agoradata/ads";
import { type SonnerProps } from "@agoradata/ads";

export { toast } from "@agoradata/ads";

export function Sonner({ ...props }: SonnerProps) {
  return <SonnerADS {...props} />;
}
