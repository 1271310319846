"use client";

import { PropsWithChildren, createContext, useContext, useMemo } from "react";

// Define the type for the context value
interface Auth0ContextType {
  empty: boolean;
}

// Create the context with a proper default value
const Auth0Context = createContext<Auth0ContextType | undefined>(undefined);

export function Auth0Provider(props: Readonly<PropsWithChildren>) {
  function renderChildren() {
    return props.children;
  }

  // Define the context value
  const contextValue = useMemo<Auth0ContextType>(
    () => ({
      empty: true,
    }),
    []
  );

  return <Auth0Context.Provider value={contextValue}>{renderChildren()}</Auth0Context.Provider>;
}

export function useAuth0Context() {
  const context = useContext(Auth0Context);

  if (typeof context === "undefined") {
    throw new Error("useAuth0Context must be used within an Auth0Provider");
  }

  return context;
}
