import { MutationFunction, useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { toast } from "app/components/Sonner";

const updateUserThemePref: MutationFunction<any, [string, string]> = async ([userId, themePref]) => {
  const body = { userId, themePref };

  if (!userId || !themePref) {
    throw new Error("Missing required parameters at hook");
  }

  try {
    const response = await axios.patch("/api/update-user-theme-pref", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

const handleError = (error: AxiosError) => {
  Sentry.captureException(error);
  toast.error("Failed to update. Please try again later.");
};

const useUpdateUserThemePref = () => {
  const mutation = useMutation(updateUserThemePref, {
    onError: handleError,
  });

  return mutation;
};

export default useUpdateUserThemePref;
